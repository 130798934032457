import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';

const CustomSelect = (props) => {
    const { sendInfo, value, setValue, items = [], error, errorText, ...rest } = props;

    return (
        <FormControl size="small" fullWidth error={error}>
            <Typography variant="body1">{sendInfo}</Typography>
            <Select
                value={value}
                onChange={e => { setValue(e.target.value); }}
                {...rest}
            >
                {/* <MenuItem value="">
                    <em>{sendInfo} seçiniz</em>
                </MenuItem> */}
                {items.map((item, index) => {
                    return (
                        <MenuItem key={index} value={item.key}>{item.value}</MenuItem>
                    )
                })}
            </Select>
            <FormHelperText>{error ? errorText : null}</FormHelperText>
        </FormControl>
    );
}

export default CustomSelect

