import React, { useEffect, useState } from 'react'
import { Grid, Typography, Box, Button } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux'
import { updateForm } from '../redux/form';
import { nextStep } from './../redux/step'

import { IMAGES } from '../utils/constants';
import CustomSelect from '../components/common/customSelect';

import ValuationService from '../services/ValuationService';
import { useTranslation } from 'react-i18next';

let brands = [
    {
        imageAlt: "Renault",
        imageSrc: IMAGES.RENAULT
    },
    {
        imageAlt: "Dacia",
        imageSrc: IMAGES.DACIA
    },
];

const Step1 = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const form = useSelector(state => state.form)

    // Form states
    const [vehicleBrand, setVehicleBrand] = useState(form.vehicleBrand)
    const [vehicleModel, setVehicleModel] = useState(form.vehicleModel)
    const [vehicleYear, setVehicleYear] = useState(form.vehicleYear)

    // Form error states
    const [vehicleBrandError, setVehicleBrandError] = useState(false)
    const [vehicleModelError, setVehicleModelError] = useState(false)
    const [vehicleYearError, setVehicleYearError] = useState(false)

    // Dropdown lists
    const [popularBrands, setPopularBrands] = useState([])
    const [brandList, setBrandList] = useState([])
    const [modelList, setModelList] = useState([])
    const [yearList, setYearList] = useState([])

    const [response, setResponse] = useState([{}])
    const [loading, setLoading] = useState(false);

    // Get data
    useEffect(() => {
        GetData()
    }, [])

    const GetData = async () => {
        try {
            const response = await ValuationService.GetBrandsAndModels();
            setResponse(response.brandsAndModels)
            setPopularBrands(response.popularBrands);
        } catch (error) {
            console.error(error);
        }
        return;
    }

    // Get brand list
    useEffect(() => {
        getBrandList()
    }, [response])

    const getBrandList = async () => {
        try {
            const uniqueBrands = Array.from(new Set(response.map(item => item.makeName))).map((brand) => ({ key: brand, value: brand }));
            let sortedBrands = uniqueBrands.sort((a, b) => a.value.localeCompare(b.value));
            setBrandList(sortedBrands);
        } catch (error) {
            console.error(error);
        }
        return;
    };

    // Get model list
    useEffect(() => {
        getModelList(vehicleBrand)
    }, [vehicleBrand])

    const getModelList = async (brand) => {
        try {
            const filtered = response.filter(item => item.makeName === brand);
            const uniqueModels = Array.from(new Set(filtered.map(item => item.modelName))).map((model) => ({ key: model, value: model }));
            let sortedModels = uniqueModels.sort((a, b) => a.value.localeCompare(b.value));

            setModelList(sortedModels);
        } catch (error) {
            console.error(error);
        }
        return;
    };

    // Get year list
    useEffect(() => {
        getYearList(vehicleModel)
    }, [vehicleModel])

    const getYearList = async (model) => {
        try {
            const findYear = response.find(item => item.modelName === model);

            if (findYear) {
                const { minYear, maxYear } = findYear;
                const range = [];

                for (let year = minYear; year <= (maxYear || new Date().getFullYear()); year++) {
                    range.push(({ key: year, value: year }));
                }

                setYearList(range.sort((a, b) => b.value - a.value));
            }
        } catch (error) {
            console.error(error);
        }
        return;
    };

    // Next step and form validation
    const handleNext = () => {
        // Form validation
        let error = false;
        if (!vehicleBrand) {
            error = true
            setVehicleBrandError(true)
        }
        if (!vehicleYear) {
            error = true
            setVehicleYearError(true)
        }
        if (!vehicleModel) {
            error = true
            setVehicleModelError(true)
        }
        if (!error) {
            // Save info to redux and proceed
            dispatch(updateForm({
                vehicleBrand,
                vehicleYear,
                vehicleModel
            }))
            setLoading(true);
            // Next step
            dispatch(nextStep())
        }
    };

    return (
        <div>
            <Typography variant="h4" p={2} textAlign="center" component="div"  >
                {t('step1Title')}
            </Typography>

            <Typography variant="body1" px={3} textAlign="center" lineHeight={1} component="div"  >
                {t('step1SubTitle')}
            </Typography>

            <Typography variant="subtitle1" px={2} py={1} textAlign="center" component="div"  >
                {t('step1PopulerBrand')}
            </Typography>

            <Grid container spacing={1} px={3} pb={3}>
                {brands.map((brand, index) => {
                    const isSelected = brand.imageAlt === vehicleBrand
                    return (
                        <Grid item xs={6} key={index} >
                            <Box border={isSelected ? '2px solid #F0DE00' : '2px solid transparent'} cursor="pointer" height="88px" bgcolor="#F0F0F0" display="flex" justifyContent="center" alignItems="center" overflow="hidden"
                                onClick={() => {
                                    setVehicleBrand(brand.imageAlt)
                                    setVehicleYear("")
                                    setVehicleModel("")
                                    setVehicleBrandError(false)
                                }}
                            >
                                <img src={brand.imageSrc} alt={brand.imageAlt} />
                            </Box>
                        </Grid>
                    )
                })}
                {popularBrands.map((item, index) => {
                    const isSelected = item.brand === vehicleBrand
                    return (
                        <Grid item xs={4} key={index} >
                            <Box border={isSelected ? '2px solid #F0DE00' : '2px solid transparent'} cursor="pointer" height="88px" bgcolor="#F0F0F0" display="flex" justifyContent="center" alignItems="center" overflow="hidden"
                                onClick={() => {
                                    setVehicleBrand(item.brand)
                                    setVehicleYear("")
                                    setVehicleModel("")
                                    setVehicleBrandError(false)
                                }}
                            >
                                <img src={item.image} alt={item.brand} />
                            </Box>
                        </Grid>
                    )
                })}
            </Grid>

            <Grid item container px={3} pb={3} xs={12}>
                <CustomSelect
                    error={vehicleBrandError}
                    errorText={t("step1BrandError")}
                    sendInfo={t("step1Brand")}
                    value={vehicleBrand}
                    setValue={setVehicleBrand}
                    items={brandList}
                    onChange={e => {
                        setVehicleBrand(e.target.value);
                        setVehicleModel("");
                        setVehicleYear("");
                        if (e.target.value) {
                            setVehicleBrandError(false)
                        }
                    }}
                />
            </Grid>

            <Grid container px={3}>
                <Typography variant="subtitle1" fontSize={20}> {t("step1ModelInfo")} </Typography>
            </Grid>

            <Grid item container px={3} xs={12}>
                <CustomSelect
                    error={vehicleModelError}
                    errorText={t("step1ModelError")}
                    sendInfo={t("step1Model")}
                    value={vehicleModel}
                    setValue={setVehicleModel}
                    items={modelList}
                    disabled={!vehicleBrand}
                    onChange={e => {
                        setVehicleModel(e.target.value);
                        setVehicleYear("");
                        if (e.target.value) {
                            setVehicleModelError(false)
                        }
                    }}
                />
            </Grid>

            <Grid item container px={3} xs={12}>
                <CustomSelect
                    error={vehicleYearError}
                    errorText={t("step1YearError")}
                    sendInfo={t("step1Year")}
                    value={vehicleYear}
                    setValue={setVehicleYear}
                    items={yearList}
                    disabled={!vehicleModel}
                    onChange={e => {
                        setVehicleYear(e.target.value);
                        if (e.target.value) {
                            setVehicleYearError(false)
                        }
                    }}
                />
            </Grid>

            <Grid px={3} pt={3} pb={3}>
                <Button variant="contained" disabled={loading} onClick={handleNext} fullWidth>{t("step1Button")}</Button>
            </Grid>
        </div>
    )
}

export default Step1