import React, { useState, useEffect } from 'react'
import { Grid, Typography, Box, Button, Dialog, TextField, Link } from '@mui/material';
import { IMAGES } from '../utils/constants';

import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';

import CustomSelect from '../components/common/customSelect';
import CustomTextField from '../components/common/customTextField';

import { useDispatch, useSelector } from 'react-redux'
import { updateForm } from '../redux/form';
import { nextStep } from './../redux/step'

import PhoneVerificationService from '../services/PhoneVerificationService';
import MaisDealerService from '../services/MaisDealerService';
import { useTranslation } from 'react-i18next';

const Step3 = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const form = useSelector(state => state.form)

    // Form states
    const [name, setName] = useState(form.name)
    const [surname, setSurname] = useState(form.surname)
    const [phone, setPhone] = useState(form.phone)
    const [email, setEmail] = useState(form.email)
    const [howCanWeHelp, setHowCanWeHelp] = useState(form.howCanWeHelp)
    const [kvkkPermission, setKvkkPermission] = useState(form.kvkkPermission)
    const [city, setCity] = useState(form.city)
    const [dealer, setDealer] = useState(form.dealer)

    // Form error states
    const [nameError, setNameError] = useState(false)
    const [surnameError, setSurnameError] = useState(false)
    const [phoneError, setPhoneError] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [howCanWeHelpError, setHowCanWeHelpError] = useState(false)
    const [kvkkPermissionError, setKvkkPermissionError] = useState(false)
    const [cityError, setCityError] = useState(false)
    const [dealerError, setDealerError] = useState(false)

    const [open, setOpen] = useState(false);

    const [smsCode, setSmsCode] = useState()
    const [smsCodeError, setSmsCodeError] = useState(false)
    const [smsErrorMessage, setSmsErrorMessage] = useState('');
    const [phoneVerificationId, setPhoneVerificationId] = useState('');

    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [isResendDisabled, setIsResendDisabled] = useState(false);
    const [remainingTime, setRemainingTime] = useState(120); // Remaining time in seconds

    const [loading, setLoading] = useState(false);

    // Dropdown lists
    const [listCity, setListCity] = useState([])
    const [listDealer, setListDealer] = useState([])
    const listHowCanWeHelp = [
        {
            key: 'NEW_VEHICLE_REQUEST',
            value: 'Yeni Araç Alacağım'
        },
        {
            key: 'USED_VEHICLE_REQUEST',
            value: 'İkinci El Araç Alacağım'
        },
        {
            key: 'VEHICLE_SELL_REQUEST',
            value: 'Aracımı Satmak İstiyorum'
        },
        {
            key: 'VEHICLE_GET_PRICE',
            value: 'Sadece Fiyat Almak İstiyorum'
        },
    ]

    // Checks if email is in correct format.
    const validEmailChecker = (email) => {
        return email.match(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i)
    }

    const PhoneVerificationSend = async () => {
        try {
            const response = await PhoneVerificationService.PhoneVerificationSend(phone);
            setPhoneVerificationId(response.phoneVerification.id)
        } catch (error) {
            setOpen(false)
            setPhoneError(true)
            console.error(error);
        }
        return;
    }

    useEffect(() => {
        GetCity()
    }, [])

    const GetCity = async () => {
        try {
            const response = await MaisDealerService.GetDealers();
            const uniqueCities = Array.from(new Set(response.dealers.map(item => item.city))).map((city) => ({ key: city, value: city }));
            let sortedCities = uniqueCities.sort((a, b) => a.value.localeCompare(b.value));
            setListCity(sortedCities)
        } catch (error) {
            console.error(error);
        }
        return;
    }

    useEffect(() => {
        GetDealers(city)
    }, [city])

    const GetDealers = async (city) => {
        try {
            const response = await MaisDealerService.GetDealers();
            const filtered = response.dealers.filter(item => item.city === city);
            const uniqueDealers = filtered.map((dealer) => ({ key: dealer.code, value: dealer.district + " - " + dealer.shortName }));

            setListDealer(uniqueDealers)
        } catch (error) {
            console.error(error);
        }
        return;
    }

    const handleResend = () => {
        PhoneVerificationSend()

        let remaining = remainingTime;

        const countdownInterval = setInterval(() => {
            remaining--;
            if (remaining <= 0) {
                clearInterval(countdownInterval);
            }
            setRemainingTime(remaining);
        }, 1000);

        setIsResendDisabled(true);
        setTimeout(() => {
            setIsResendDisabled(false);
        }, remaining * 1000);
    };

    const CheckOTPCode = async () => {
        try {
            const response = await PhoneVerificationService.CheckOTPCode(phoneVerificationId, smsCode);
            if (response.status === "Success") {
                handleClose()
                // Next step
                dispatch(nextStep())
            } else {
                setSmsCodeError(true)
                console.error('Kod Hatalı!', response.status);
            }
        } catch (error) {
            setSmsCodeError(true)
            setSmsErrorMessage(error.message)
            console.error(error);
        }
        return;
    }

    const handleClickOpen = () => {
        setOpen(true);
        PhoneVerificationSend()
    };

    const handleClose = (event, reason) => {
        // Dialog dışına tıklandığında ve esc tuşuna basıldığında kapanmasını engellemek için kontrol
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            setSmsCode('')
            setOpen(false);
        }
    };

    const handleNext = () => {
        // Form validation
        let error = false;
        if (!name) {
            error = true
            setNameError(true)
        }
        if (!surname) {
            error = true
            setSurnameError(true)
        }
        if (!phone) {
            error = true
            setPhoneError(true)
        }
        if (!email || !validEmailChecker(email)) {
            error = true
            setEmailError(true)
        }
        if (!howCanWeHelp) {
            error = true
            setHowCanWeHelpError(true)
        }
        if (!kvkkPermission) {
            error = true
            setKvkkPermissionError(true)
        }
        if (howCanWeHelp != 'VEHICLE_GET_PRICE') {
            if (!city) {
                error = true
                setCityError(true)
            }
            if (!dealer) {
                error = true
                setDealerError(true)
            }
        }
        if (!error) {
            // Save info to redux and proceed
            dispatch(updateForm({
                name,
                surname,
                phone,
                email,
                howCanWeHelp,
                kvkkPermission,
                city,
                dealer
            }))
            setLoading(true);
            handleClickOpen()
        }
    }

    return (
        <div>
            <Dialog onClose={handleClose} open={open}>
                <div style={{ maxWidth: '450px' }}>
                    <Grid px={1} py={3}>
                        <Typography variant="h5" textAlign="center" component="div"  >
                            {t("step3Sms")}
                        </Typography>
                    </Grid>
                    <Grid px={7} pb={3}>
                        <CustomTextField
                            error={smsCodeError}
                            errorText={smsErrorMessage}
                            value={smsCode}
                            onChange={e => {
                                setSmsCode(e.target.value.replace(/\s/g, ''));
                                if (e.target.value.replace(/\s/g, '').length == 4) {
                                    setButtonDisabled(false)
                                    setSmsCodeError(false)
                                } else {
                                    setButtonDisabled(true)
                                }
                            }}
                            textFieldType="sms"
                        />
                    </Grid>
                    <Grid px={7} pb={5} textAlign={"center"}>
                        <Button color="inherit" disabled={isResendDisabled} onClick={handleResend}>
                            <Link variant="caption" color="inherit" >
                                {t("step3Again")} {isResendDisabled ? `(${remainingTime})` : null}
                            </Link>
                        </Button>
                    </Grid>
                    <Grid px={7} pb={5}>
                        <Button variant="contained" onClick={CheckOTPCode} disabled={buttonDisabled} fullWidth>{t("step3CheckButton")}</Button>
                    </Grid>

                </div>
            </Dialog>

            <Box pb={2} display="flex" justifyContent="center" alignItems="center" overflow="hidden">
                <img src={IMAGES.CAPTUR} alt="Resim Açıklaması" style={{ width: '100%' }} />
            </Box>

            <Grid item px={3} xs={12}>
                <Typography variant="h5"> {form.vehicleBrand} {form.vehicleModel}  </Typography>
                <Typography variant="h6"> {form.vehicleShapeType} {form.vehicleEngineNameHP} </Typography>
                <Typography variant="subtitle1"> {form.vehicleYear} Model | {form.vehicleGearType} Vites | {form.vehicleFuelType} | {form.km} KM </Typography>
            </Grid>

            <Box component="form" autoComplete="off">
                <Grid item px={3} xs={12}>
                    <CustomTextField
                        error={nameError}
                        errorText={t("step3NameError")}
                        sendInfo={t("step3Name")}
                        value={name}
                        onChange={e => {
                            setName(e.target.value);
                            if (e.target.value) {
                                setNameError(false)
                            }
                        }}
                    />
                </Grid>
                <Grid item px={3} xs={12}>
                    <CustomTextField
                        error={surnameError}
                        errorText={t("step3SurnameError")}
                        sendInfo={t("step3Surname")}
                        value={surname}
                        onChange={e => {
                            setSurname(e.target.value);
                            if (e.target.value) {
                                setSurnameError(false)
                            }
                        }}
                    />
                </Grid>
                <Grid item px={3} xs={12}>
                    <CustomTextField
                        error={phoneError}
                        errorText={t("step3PhoneError")}
                        sendInfo={t("step3Phone")}
                        value={phone}
                        onChange={(phone, e) => {
                            setPhone(e.nationalNumber);
                            if (e.nationalNumber) {
                                setPhoneError(false)
                            }
                        }}
                        textFieldType="phone"
                    />
                </Grid>
                <Grid item px={3} xs={12}>
                    <CustomTextField
                        error={emailError}
                        errorText={t("step3EmailError")}
                        sendInfo={t("step3Email")}
                        value={email}
                        onChange={e => {
                            setEmail(e.target.value);
                            if (e.target.value) {
                                setEmailError(false)
                            }
                        }}
                    />
                </Grid>
                <Grid item px={3} xs={12}>
                    <CustomSelect
                        error={howCanWeHelpError}
                        errorText={t("step3ServiceError")}
                        sendInfo={t("step3Service")}
                        value={howCanWeHelp}
                        items={listHowCanWeHelp}
                        onChange={e => {
                            setHowCanWeHelp(e.target.value);
                            if (e.target.value) {
                                setHowCanWeHelpError(false)
                            }
                        }}
                    />
                </Grid>
                {howCanWeHelp && howCanWeHelp !== 'VEHICLE_GET_PRICE' &&
                    <>
                        <Grid item px={3} xs={12}>
                            <CustomSelect
                                error={cityError}
                                errorText={t("step3CityError")}
                                sendInfo={t("step3City")}
                                value={city}
                                items={listCity}
                                onChange={e => {
                                    setCity(e.target.value);
                                    setDealer("");
                                    if (e.target.value) {
                                        setCityError(false)
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item px={3} xs={12}>
                            <CustomSelect
                                error={dealerError}
                                errorText={t("step3DealerError")}
                                sendInfo={t("step3Dealer")}
                                value={dealer}
                                items={listDealer}
                                disabled={!city}
                                onChange={e => {
                                    setDealer(e.target.value);
                                    if (e.target.value) {
                                        setDealerError(false)
                                    }
                                }}
                            />
                        </Grid>
                    </>
                }
                <Grid item px={3} xs={12}>
                    <FormControl error={kvkkPermissionError}>
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox
                                    checked={kvkkPermission}
                                    onChange={e => {
                                        setKvkkPermission(e.target.checked);
                                        if (e.target.checked) {
                                            setKvkkPermissionError(false)
                                        }
                                    }}

                                />}
                                label={
                                    <Typography variant="body2" sx={{ opacity: 0.3 }} >
                                        {t("step3Kvkk")}
                                    </Typography>}
                            />
                        </FormGroup>

                        <FormHelperText>{kvkkPermissionError ? t("step3KvkkError") : null}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item px={3} xs={12}>
                    <Typography variant="body2" lineHeight={1}>
                        {t("step3Conditions")}
                    </Typography>
                </Grid>
                <Grid item px={3} pb={3} pt={2} xs={12}>
                    <Button variant="contained" disabled={loading} color='success' onClick={handleNext} fullWidth>{t("step3Button")} </Button>
                </Grid>
            </Box>
        </div>
    )
}

export default Step3