import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { trTR, enUS } from '@mui/material/locale';

const DEFAULT_THEME = {
  palette: {
    white: "#FFFFFF",
    black: "#000000",
    darkGray: "#3E3F40",
    gray: "#8C8C8B",
    lightGray: "#D9D9D6",
    super_primary: "#F0DE00",
    super_primary_hover: "#F8EB4C",
    primary: "#000000",
    primary_hover: "#EFDF00",
  },
  typography: {
    primaryBackgroundColor: "#000000 !important",
    primaryHoverBackgroundColor: "#EFDF00 !important",
    superPrimaryCloseButton: "#EFDF00 !important",

    fontFamily: "NouvelR-Regular",
    fontFamilyRegular: "NouvelR-Regular",
    fontFamilyBook: "NouvelR-Book",
    fontFamilyBold: "NouvelR-Bold",
    fontFamilyExtrabold: "NouvelR-Extrabold",
  }
};

const useThemes = () => {
  const { i18n } = useTranslation();
  const coreLanguage = i18n.language === "en" ? enUS : trTR

  function getTheme() {
    const theme = createTheme({
      palette: {
        // type: isDark ? 'dark' : 'light',
        primary: {
          main: DEFAULT_THEME.palette.primary,
        },
        secondary: {
          main: DEFAULT_THEME.palette.primary,
        },
        blue: {
          main: "#1f2532",
        },
        common: {
          transparent: "#ffffff00",
        },
        shape: {
          borderRadius: 4,
        },
        success: {
          main: DEFAULT_THEME.palette.super_primary,
        },
      },
      typography: {
        fontFamily: DEFAULT_THEME.typography.fontFamily,

        h1: {// 96px

        },
        h2: {// 60px

        },
        h3: {// 48px

        },
        h4: {// 34px
          fontFamily: DEFAULT_THEME.typography.fontFamilyBold,
        },
        h5: {// 24px
          fontFamily: DEFAULT_THEME.typography.fontFamilyBold,
        },
        h6: {// 20px
          fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
        },
        subtitle1: {// 16px
          fontFamily: DEFAULT_THEME.typography.fontFamilyBold,
        },
        subtitle2: {// 14px
          fontFamily: DEFAULT_THEME.typography.fontFamilyBold,
        },
        body1: {// 16px
          fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
        },
        body2: {// 14px
          fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
        },
      },
      breakpoints: {
        values: {
          // extra-small
          xs: 0,
          // small
          sm: 600,
          // medium
          md: 900,
          // large
          lg: 1200,
          // extra-large
          xl: 1600,
        }
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: "none",
              fontSize: "1rem",
              borderRadius: 0,
              fontFamily: DEFAULT_THEME.typography.fontFamilyBold,
            },

          },

          // containedSecondary: {
          //     '&:hover': {
          //         backgroundColor: '#000',
          //     }
          // },
        },
        MuiTypography: {
          styleOverrides: {
            root: {
              //
            },

          },
        },
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              borderRadius: 0
            },
          },
        },
        MuiInput: {
          styleOverrides: {
            root: {
              borderColor: "white",
            },
            underline: {
              "&:hover:not($disabled):before": {
                borderColor: DEFAULT_THEME.palette.gray,
              },
            },
          },
        },
        MuiCheckbox: {
          defaultProps: {
            //disableRipple: 'true'
          },
          styleOverrides: {
            root: {
              "&.Mui-checked": {
                color: "#000000",
              },

            },
          }
        },
        MuiListItem: {
          defaultProps: {
            disableRipple: 'true'
          },
        },
        MuiAccordionSummary: {
          styleOverrides: {
            content: {
              flexGrow: 0
            }
          }
        },
        MuiCollapse: {
          styleOverrides: {
            root: {
              textAlign: "center"
            }
          }
        },
        MuiTextField: {
          styleOverrides: {
            root: {
              "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                borderColor: DEFAULT_THEME.palette.gray,
              },
              "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                borderColor: DEFAULT_THEME.palette.gray,
              },
              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                borderColor: "#000000",
                border: "2px solid",
              },
            },
          },
        },
        MuiStepper: {
          styleOverrides: {
            root: {
              "& .MuiStepConnector-root": {
                top: "10px",
                left: "calc(-50% + 10px)",
                right: "calc(50% + 10px)"
              },
              "& .MuiStepConnector-line": {
                borderTopWidth: "2px"
              },
            },
          },
        },
        MuiGrid: {
          styleOverrides: {
            root: {
              '.MuiGrid-item': {
                // paddingTop: 0,
                // paddingLeft: 0
              }

            }
          },
        }

      }

    },
      coreLanguage,
      //#TODO uncomment for datapicker and datagrid locale language
      // dataGridLanguage,
      // datePickerLanguage
    );

    // return theme
    return responsiveFontSizes(theme);
  }
  return getTheme
}


export { useThemes, DEFAULT_THEME };
