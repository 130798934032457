// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/fonts/NouvelR-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./assets/fonts/NouvelR-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./assets/fonts/NouvelR-Book.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
  margin: 0;
  scroll-behavior: smooth;
}

/* Disables spinner at the end of the html input element with type number */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

body::-webkit-scrollbar {
  display: none;
}

@font-face {
  font-family: "NouvelR-Regular";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  /* IE9 Compat Modes */
  src: local("NouvelR-Regular"), local("NouvelR-Regular"), url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "NouvelR-Bold";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
  /* IE9 Compat Modes */
  src: local("NouvelR-Bold"), local("NouvelR-Bold"), url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "NouvelR-Book";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
  /* IE9 Compat Modes */
  src: local("NouvelR-Book"), local("NouvelR-Book"), url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("truetype");
  font-weight: normal;
}`, "",{"version":3,"sources":["webpack://./src/index.scss"],"names":[],"mappings":"AAAA;EACE,SAAA;EACA,mCAAA;EACA,kCAAA;AACF;;AAIA;EACE,sBAAA;EACA,SAAA;EACA,uBAAA;AADF;;AAKA,2EAAA;AACA;;EAEI,aAAA;EACA,wBAAA;EACA,SAAA;AAFJ;;AAIA;EACI,0BAAA;AADJ;;AAIA;EACE,aAAA;AADF;;AAIA;EACE,8BAAA;EACA,4CAAA;EACA,qBAAA;EACA,mHAAA;EAEA,mBAAA;AAFF;AAKA;EACE,2BAAA;EACA,4CAAA;EACA,qBAAA;EACA,6GAAA;EAEA,iBAAA;AAJF;AAOA;EACE,2BAAA;EACA,4CAAA;EACA,qBAAA;EACA,6GAAA;EAEA,mBAAA;AANF","sourcesContent":["body {\n  margin: 0;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n#root {\n  //overflow: hidden;\n}\n* {\n  box-sizing:border-box;\n  margin: 0;\n  scroll-behavior: smooth;\n  \n}\n\n/* Disables spinner at the end of the html input element with type number */\ninput::-webkit-outer-spin-button,\ninput::-webkit-inner-spin-button {\n    display: none;\n    -webkit-appearance: none;\n    margin: 0;\n}\ninput[type=number] {\n    -moz-appearance:textfield;\n}\n\nbody::-webkit-scrollbar {\n  display: none;\n}\n\n@font-face {\n  font-family: \"NouvelR-Regular\";\n  src: url('./assets/fonts/NouvelR-Regular.ttf');\n  /* IE9 Compat Modes */\n  src: local('NouvelR-Regular'), local('NouvelR-Regular'),\n    url(\"./assets/fonts/NouvelR-Regular.ttf\") format(\"truetype\");\n  font-weight: normal;\n}\n\n@font-face {\n  font-family: \"NouvelR-Bold\";\n  src: url('./assets/fonts/NouvelR-Bold.ttf');\n  /* IE9 Compat Modes */\n  src: local('NouvelR-Bold'), local('NouvelR-Bold'),\n    url(\"./assets/fonts/NouvelR-Bold.ttf\") format(\"truetype\");\n  font-weight: bold;\n}\n\n@font-face {\n  font-family: \"NouvelR-Book\";\n  src: url('./assets/fonts/NouvelR-Book.ttf');\n  /* IE9 Compat Modes */\n  src: local('NouvelR-Book'), local('NouvelR-Book'),\n    url(\"./assets/fonts/NouvelR-Book.ttf\") format(\"truetype\");\n  font-weight: normal;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
