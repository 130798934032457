import React from 'react'
import { Typography, TextField } from '@mui/material';
import { NumericFormat, PatternFormat } from 'react-number-format';
import { MuiTelInput } from 'mui-tel-input';

const CustomTextField = (props) => {
    const { textFieldType = "default", sendInfo, error, errorText, onChange, ...rest } = props;
    return (
        textFieldType === "default" ? (
            <div>
                <Typography variant='body1'> {sendInfo} </Typography>
                <TextField
                    onChange={onChange}
                    error={error}
                    helperText={error ? errorText : null}
                    size="small"
                    fullWidth
                    {...rest}
                />
            </div>
        ) : textFieldType === "numeric" ? (
            <div>
                <Typography variant='body1'> {sendInfo} </Typography>
                <NumericFormat
                    onChange={onChange}
                    error={error}
                    helperText={error ? errorText : null}
                    size="small"
                    fullWidth
                    customInput={TextField}
                    allowLeadingZeros
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    {...rest}
                />
            </div>
        ) : textFieldType === "phone" ? (
            <div>
                <Typography variant='body1'> {sendInfo} </Typography>
                <MuiTelInput
                    onChange={onChange}
                    className="no-flags"
                    error={error}
                    helperText={error ? errorText : null}
                    size="small"
                    fullWidth
                    forceCallingCode
                    disableDropdown
                    defaultCountry={"TR"}
                    inputProps={{ maxLength: 13 }}
                    {...rest}
                />
            </div>
        ) : textFieldType === "sms" ? (
            <PatternFormat
                onChange={onChange}
                error={error}
                helperText={error ? errorText : null}
                size="small"
                fullWidth
                customInput={TextField}
                inputProps={{ style: { textAlign: 'center', fontSize: '1.5em' } }}
                format="# # # #"
                {...rest}
            />
        ) : null

    );
}

export default CustomTextField