function isLetter(char) {
  return ((char >= 'A' && char <= 'Z') ||
    (char >= 'a' && char <= 'z'));
}

export function modifyPlate(plate) {

  if (!plate) return ''

  const splittedPlate = plate.match(new RegExp('([0-9]+)|([a-zA-Z]+)', 'g'));

  if (!splittedPlate) return ''

  return splittedPlate.join(' ').toUpperCase()

}
export default isLetter