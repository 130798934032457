// Interceptor 
import axios from '../utils/interceptor'
import { API_KEY, HEADERS } from '../utils/constants'

class MaisDealerService {

    GetDealers = async () => {
        let headers = {
            [HEADERS.AUTHORIZATION]: API_KEY.DEALERS_TOKEN,
        }
        try {
            // Request
            let result = await axios.get('/mais/dealers', { headers })
            //Check Error
            if (result.data.error) return Promise.reject(result.data.error)

            //Return
            return result.data.data

        } catch (e) {
            return Promise.reject(e)
        }
    }

}

export default new MaisDealerService()