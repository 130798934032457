//disable yellow errors for this page
/* eslint-disable import/no-anonymous-default-export */

export default {

    tr: {
        translation: {
            languageButton: "TR",
            language: "Tr",

            //Step0
            step0Title: "Aracının değerini öğrenmek ister misin?",
            step0SubTitle: "Aracının plakasını ve km’sini bizimle paylaşır mısın?",
            step0Km: "Kilometre",
            step0LicensePlate: "Aracın Plakası",

            step0KmError: "Lütfen kilometre giriniz",
            step0LicensePlateError: "Lütfen plaka giriniz",

            step0StartButton: "Başla",

            //Step0 Dialog
            step0TitleDialog: "Fiyatlamak istediğiniz araç bu mu?",
            step0ButtonDialogYes: "Bu araçla devam etmek istiyorum",
            step0ButtonDialogNo: "Hayır",

            //Step1
            step1Title: "Aracımı Değerle",
            step1SubTitle: "Aracınızın değerini öğrenmek istiyorsanız doğru yerdesiniz. Başlamak için marka seçiniz.",
            step1PopulerBrand: "Popüler Markalar",

            step1Brand: "Marka",
            step1ModelInfo: "Model Bilgisi",
            step1Model: "Model",
            step1Year: "Model yılı",

            step1BrandError: "Lütfen marka seçiniz",
            step1ModelError: "Lütfen model seçiniz",
            step1YearError: "Lütfen model yılı seçiniz",

            step1Button: "Başla",

            //Step2
            step2Title: "Aracınızın özelliklerini seçin",

            step2GearType: "Vites",
            step2FuelType: "Yakıt",
            step2ShapeType: "Kasa",
            step2VersionType: "Versiyon",
            step2Engine: "Motor",
            step2TrimName: "Donanım",
            step2VehicleFaceLift: "Araç Makyajı",
            step2Color: "Renk",
            step2PaintType: "Boya Tipi",

            step2GearTypeError: "Lütfen vites seçiniz",
            step2FuelTypeError: "Lütfen yakıt seçiniz",
            step2ShapeTypeError: "Lütfen kasa seçiniz",
            step2VersionTypeError: "Lütfen versiyon seçiniz",
            step2EngineError: "Lütfen motor seçiniz",
            step2TrimNameError: "Lütfen donanım seçiniz",
            step2VehicleFaceLiftError: "Lütfen araç makyajı seçiniz",
            step2ColorError: "Lütfen renk seçiniz",
            step2PaintTypeError: "Lütfen boya tipi seçiniz",

            step2ContinueButton: "Devam Et",

            //Step3
            step3Title: "Hasar Tespiti ve Aksesuar seçimi",
            step3SubTitle: "Aşağıdaki bilgiler aracın aksesuar ve hasar durumunu detaylı şekilde sunmaktadır",

            //Step4
            step4Name: "Ad",
            step4Surname: "Soyad",
            step4Phone: "Telefon",
            step4Email: "E-Posta",
            step4Service: "Hizmet",
            step4City: "Şehir",
            step4Dealer: "Bayi",
            step4Kvkk: "*İletişim, Kişisel Verilerin saklanması ve işlenmesi onayı",

            step4NameError: "Lütfen adınızı giriniz",
            step4SurnameError: "Lütfen soyadınızı giriniz",
            step4PhoneError: "Lütfen geçerli bir telefon numarası giriniz",
            step4EmailError: "Lütfen geçerli bir e-posta adresi giriniz",
            step4ServiceError: "Lütfen hizmet seçiniz",
            step4CityError: "Lütfen şehir seçiniz",
            step4DealerError: "Lütfen bayi seçiniz",
            step4KvkkError: "Devam etmek için onay verin",
            step4Conditions: "* Mais Motorlu Araçlar İmal ve Satış A.Ş., Yetkili Satıcıları, Yetkili Servisleri, OYAK ve Renault Grup Şirketleri tarafından, Elektronik Ticaretin Düzenlenmesi Hakkındaki Kanun uyarınca, her türlü ticari elektronik ileti gönderilmek suretiyle, tüm iletişim araçları ile tarafınızla iletişime geçilmesine, bu amaçla paylaşmış olduğunuz bilgilerin Renault gizlilik politikası ile kişisel verilerin korunması ve saklanması yasal düzenlemelerine uygun olarak saklanmasına ve işlenmesine muvafakat edersiniz.",

            step4Button: "Aracımı fiyatla",

            step4Sms: "Telefonunuza gelen doğrulama kodunu giriniz.",
            step4Again: "Tekrar gönder",
            step4CheckButton: "Doğrula",

            //Step5
            step5SubTitleValuation: "Aracınıza, değerleme yaparken hata oluştu. Lütfen tekrar deneyiniz.",
            step5Conditions: `* "renew 150 Nokta Kontrol" öncesi tahmini fiyat bilgisi, tarafınızca girilen araç bilgileri olan markası, modeli, kilometresi ve donanım paketi belirtilen aracın özellikleri dikkate alınarak belirlenmiştir.<br />* Aracın fiyatlaması, tarafınızdan beyan üzerine alınan bilgiler ışığında yapılmıştır. Aracın renew şebekesindeki ekspertizi tamamlandıktan sonra, 150 nokta kontrolü esnasında yapılacak kontroller neticesinde beyan edilen bilgilerin doğru olmaması durumu ile karşılaşılırsa renew şebekesi fiyatta değişiklik yapma hakkını haizdir.<br />* Alımı yapılmayacak olan araçlar şunlardır: Taksi çıkması, airbag işlemli, şase-podye işlemli, tavan boyalı veya işlemli, kilometresi ile oynanmış, distribütör harici ithal edilmiş, ağır hasar, sel, su baskını, araç çalınma-bulunma veya yangın kaynaklı bir tramer kaydının olması<br />* Bununla beraber aracınıza "renew 150 Nokta Kontrol" uygulaması tatbik edildikten sonra aracın mekanik ve kaporta aksamındaki durumuna göre de tavsiye edilen fiyattan daha düşük bir fiyatın tespit edilmesi söz konusu olabilecektir. <br />* Fiyat teklifi 1 gün için geçerlidir.`

        }
    },
    en: {
        translation: {
            languageButton: "EN",
            language: "En",

            //Step0
            step0Title: "Would you like to know the value of your car?",

        }
    }
}
