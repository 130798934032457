// Interceptor 
import axios from '../utils/interceptor'
import { API_KEY, HEADERS } from '../utils/constants'

class PhoneVerificationService {

    PhoneVerificationSend = async (phone) => {
        let body = {
            type: "valuation"
        }
        let headers = {
            [HEADERS.AUTHORIZATION]: API_KEY.PHONE_VERIFICATION_TOKEN,
        }
        try {
            // Request
            let result = await axios.post(`/phoneVerifications/90${phone}`, body, { headers })
            //Check Error
            if (result.data.error) return Promise.reject(result.data.error)

            //Return
            return result.data.data

        } catch (e) {
            return Promise.reject(e)
        }
    }

    CheckOTPCode = async (phoneVerificationId, code) => {
        let body = {
            phoneVerificationId: phoneVerificationId,
            code: code
        }
        let headers = {
            [HEADERS.AUTHORIZATION]: API_KEY.PHONE_VERIFICATION_TOKEN,
        }
        try {
            // Request
            let result = await axios.post('/phoneVerifications/verify/otp', body, { headers })
            //Check Error
            if (result.data.error) return Promise.reject(result.data.error)

            //Return
            return result.data

        } catch (e) {
            return Promise.reject(e)
        }
    }

}

export default new PhoneVerificationService()