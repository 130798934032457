import React, { useEffect, useState } from 'react';
import { Grid, Typography, Box, Button, Dialog } from '@mui/material';
import { IMAGES } from '../utils/constants';
import { useDispatch, useSelector } from 'react-redux'
import CustomTextField from '../components/common/customTextField';
import { updateForm, removeForm } from '../redux/form';
import { nextStep, setStep } from './../redux/step'

import ValuationService from '../services/ValuationService';
import { useTranslation } from 'react-i18next';
import { modifyPlate } from "../utils/modifyPlate"
import { useSearchParams } from 'react-router-dom';

const Step0 = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const form = useSelector(state => state.form)
    const [searchParams, setSearchParams] = useSearchParams();

    const [km, setKm] = useState(form.km);
    const [licensePlate, setLicensePlate] = useState(form.licensePlate);

    // Form error states
    const [kmError, setKmError] = useState(false);
    const [licensePlateError, setLicensePlateError] = useState(false);

    const [open, setOpen] = useState(false);
    const [vehicleInfo, setVehicleInfo] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (km.length < 1) {
            setKm(searchParams.get('km'))
        }
        if (licensePlate.length < 1) {
            setLicensePlate(modifyPlate(searchParams.get('plate')))
        }
    }, [])


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleNext = async () => {
        // Form validation
        let error = false;
        if (!km) {
            error = true
            setKmError(true)
        }
        if (!licensePlate) {
            error = true
            setLicensePlateError(true)
        }
        if (!error) {
            // Save info to redux and proceed
            dispatch(updateForm({
                km,
                licensePlate
            }))
            try {
                setLoading(true);
                const response = await ValuationService.GetVehicleInfo(licensePlate);
                setVehicleInfo(response.vehicleInfo);

                dispatch(updateForm({
                    km,
                    licensePlate,
                    vehicleBrand: response.vehicleInfo.marka,
                    vehicleYear: response.vehicleInfo.year,
                    vehicleModel: response.vehicleInfo.model,

                    vehicleGearType: response.vehicleInfo.transmissionType,
                    vehicleFuelType: response.vehicleInfo.fuelClass,
                    vehicleShapeType: null,
                }))
                handleClickOpen();
            } catch (error) {
                console.error(error);
                // Next step
                dispatch(nextStep())
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <>
            <Dialog onClose={handleClose} open={open} >
                <div style={{ maxWidth: '450px', padding: "10px" }}>
                    <Box display="flex" pb={3} justifyContent="center" alignItems="center" overflow="hidden">
                        <img src={IMAGES.SPRING} alt="Resim Açıklaması" style={{ width: '100%' }} />
                    </Box>

                    <Typography variant="h5" px={2} pb={3} textAlign="center" component="div"  >
                        {t("step0TitleDialog")}
                    </Typography>
                    <Typography variant="h4" px={5} pb={"1px"} textAlign="center" component="div"  >
                        {vehicleInfo.marka} {vehicleInfo.model}
                    </Typography>
                    <Typography variant="h5" px={5} pb={1} textAlign="center" component="div"  >
                        {vehicleInfo.version}
                    </Typography>
                    <Typography variant="h6" px={6} pb={10} textAlign="center" component="div"  >
                        {vehicleInfo.year} - {vehicleInfo.km} KM - {vehicleInfo.fuelClass} - {vehicleInfo.transmissionType}
                    </Typography>

                    <Grid mb={2}>
                        <Button
                            variant="contained"
                            fullWidth
                            color='success'
                            onClick={() => dispatch(setStep(3))}
                        >
                            {t("step0ButtonDialogYes")}
                        </Button>
                    </Grid>

                    <Grid >
                        <Button
                            variant="contained"
                            fullWidth
                            onClick={() => {
                                dispatch(removeForm());
                                dispatch(updateForm({
                                    km,
                                    licensePlate
                                }));
                                dispatch(nextStep());
                            }}
                        >
                            {t("step0ButtonDialogNo")}
                        </Button>
                    </Grid>
                </div>
            </Dialog>

            <Box display="flex" justifyContent="center" alignItems="center" overflow="hidden">
                <img src={IMAGES.NIAGARA} alt="Resim Açıklaması" style={{ width: '100%' }} />
            </Box>
            <Typography variant="h5" px={6} pt={1} textAlign="center" gutterBottom >
                {t("step0Title")}
            </Typography>
            <Typography variant="h6" px={5} pb={1.8} textAlign="center" lineHeight={1} >
                {t("step0SubTitle")}
            </Typography>
            <Box component="form" autoComplete="off">
                <Grid px={3}>
                    <CustomTextField
                        error={kmError}
                        errorText={t("step0KmError")}
                        sendInfo={t("step0Km")}
                        value={km}
                        onChange={e => {
                            setKm(parseInt(e.target.value.replace(/\./g, "")))
                            if (e.target.value) {
                                setKmError(false)
                            }
                        }}
                        textFieldType="numeric"
                    />
                </Grid>
                <Grid px={3}>
                    <CustomTextField
                        error={licensePlateError}
                        errorText={t("step0LicensePlateError")}
                        sendInfo={t("step0LicensePlate")}
                        value={licensePlate}
                        onChange={e => {
                            setLicensePlate(modifyPlate(e.target.value));
                            if (e.target.value) {
                                setLicensePlateError(false)
                            }
                        }}
                    />
                </Grid>
                <Grid px={3} pt={3} pb={3}>
                    <Button variant="contained" disabled={loading} onClick={handleNext} fullWidth>{t("step0StartButton")}</Button>
                </Grid>
            </Box>
        </>
    )
}

export default Step0