import { createSlice } from '@reduxjs/toolkit';
import { STEPS } from './../utils/constants';

const initialState = STEPS.step0;

const stepSlice = createSlice({
    name: 'step',
    initialState,
    reducers: {
        setStep(state, action) {
            return action.payload;
        },
        removeStep(state) {
            return initialState;
        },
        nextStep(state) {
            if (state === STEPS.step5) {
                return STEPS.step5;
            } else {
                return state + 1;
            }
        },
        previousStep(state) {
            if (state === STEPS.step0) {
                return STEPS.step0;
            } else {
                return state - 1;
            }
        },
        initialStep() {
            return initialState;
        },
    },
});

export const { setStep, removeStep, nextStep, previousStep, initialStep } = stepSlice.actions;
export default stepSlice.reducer;