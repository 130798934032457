import { configureStore } from '@reduxjs/toolkit';
import stepReducer from './step';
import formReducer from './form';
import loading from "./loading";
import snackbarReducer from "./snackbar";

export default configureStore({
    reducer: {
        loading: loading,
        step: stepReducer,
        form: formReducer,
        snackbar: snackbarReducer,
    },
    devTools: process.env.REACT_APP_ENV !== 'production',
});