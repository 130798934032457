import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    km: '',
    licensePlate: '',

    vehicleBrand: '',
    vehicleYear: '',
    vehicleModel: '',

    vehicleGearType: '',
    vehicleFuelType: '',
    vehicleShapeType: '',
    vehicleEngineNameHP: '',
    vehicleTrimName: '',
    vehicleFaceLift: '',

    vehicleEngineName: '',
    vehicleFaceLiftFrom: '',
    vehicleFaceLiftTo: '',

    name: '',
    surname: '',
    phone: '',
    email: '',
    howCanWeHelp: '',
    city: '',
    dealer: '',

    kvkkPermission: false,
    dataSource: 'Renew',
};

const formSlice = createSlice({
    name: 'form',
    initialState,
    reducers: {
        setForm: (state, action) => {
            return action.payload;
        },
        removeForm: () => {
            return initialState;
        },
        updateForm: (state, action) => {
            return {
                ...state,
                ...action.payload,
            };
        },
    },
});

export const { setForm, removeForm, updateForm, } = formSlice.actions;
export default formSlice.reducer;