import React from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { STEPS } from '../utils/constants'
import { Grid } from '@mui/material';

import HorizontalLinearStepper from '../components/HorizontalLinearStepper'
import Step0 from '../routes/step0'
import Step1 from '../routes/step1'
import Step2 from '../routes/step2'
import Step3 from '../routes/step3'

import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

function SourceStepper(source) {
    const activeStep = useSelector(state => state.step)
    return (
        <HorizontalLinearStepper source={source}>
            {/* Step 0 */}
            {activeStep === STEPS.step0 && <Step0 />}
            {/* Step 1 */}
            {activeStep === STEPS.step1 && <Step1 />}
            {/* Step 2 */}
            {activeStep === STEPS.step2 && <Step2 />}
            {/* Step 3 */}
            {activeStep === STEPS.step3 && <Step3 />}
        </HorizontalLinearStepper>
    );
}

const Index = (props) => {
    const dispatch = useDispatch()

    return (
        <Grid container justifyContent="center" alignItems="center" height={{ sm: '100vh' }} >
            <BrowserRouter>
                <Routes>
                    <Route path='/renault' element={SourceStepper('Renault')} />
                    <Route path='/dacia' element={SourceStepper('Dacia')} />
                    <Route path='/renew' element={SourceStepper('Renew')} />
                    <Route path='/myrenault' element={SourceStepper('myrenault')} />
                    <Route path='/portRenault' element={SourceStepper('Renault Port')} />
                    <Route path='/portDacia' element={SourceStepper('Dacia Rort')} />
                    <Route path='/renault-port' element={SourceStepper('Renault Port')} />
                    <Route path='/dacia-port' element={SourceStepper('Dacia Rort')} />


                    <Route path='/' element={SourceStepper('Renew')} />
                    <Route path='*' element={<Navigate to="/" replace />} />
                </Routes>
            </BrowserRouter>
        </Grid>
    )

}

export default Index