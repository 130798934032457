import React, { useEffect, useState } from 'react';
import {
  Button,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Radio,
  Checkbox,
  Card,
  Divider,
  Alert
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { showError } from '../redux/snackbar';
import { updateForm } from '../redux/form';
import { nextStep } from '../redux/step'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';

const styles = {
  summary: {
    justifyContent: 'space-between',
  },
  accordionTetx: {
    fontWeight: 'bold',
  },
  alert: {
    backgroundColor: '#F0F0F0',
    color: '#000',
    alignItems: 'center',
    textAlign: 'left',
  },
};

const Step3 = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation();

  const [damageSelection, setDamageSelection] = useState({});
  const [accessorySelection, setAccessorySelection] = useState({});
  const [openAccordion, setOpenAccordion] = useState("damage");
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    handleSelectAllOriginal();
    const newSelection = {};
    accessoryData.forEach(({ items }) => {
      items.forEach(({ field }) => {
        newSelection[field] = false;
      });
    });
    setAccessorySelection((prev) => ({ ...prev, ...newSelection }));
  }, []);

  const handleDamageChange = (field, value) => {
    const newDamageSelection = { ...damageSelection, [field]: value };

    setDamageSelection(newDamageSelection);
  };

  const isDamageDataValid = () => {
    return damageData.every(({ field }) => damageSelection[field]);
  };

  const handleSelectAllOriginal = () => {
    const newDamageSelection = {};
    damageData.forEach(({ field }) => { newDamageSelection[field] = 'original'; });
    setDamageSelection(newDamageSelection);
  };

  const handleAccessoryChange = (field, value) => {
    setAccessorySelection({ ...accessorySelection, [field]: value });
  };

  const handleMainAccordionChange = (panel) => (event, isExpanded) => {
    setOpenAccordion(isExpanded ? panel : false);
  };
  const handleInnerAccordionChange = (category) => (event, isExpanded) => {
    setExpanded(isExpanded ? category : false);
  };

  const handleNextStep = () => {
    if (!isDamageDataValid()) {
      dispatch(showError({ message: 'Lütfen hasar verilerinden her biri için bir seçim yapın.' }));
      return;
    }

    dispatch(updateForm({ expertize: { ...damageSelection, ...accessorySelection } }));
    dispatch(nextStep());
  };

  const damageData = [
    { label: 'Kaput', field: 'front_bonnet' },
    { label: 'Sol Ön Çamurluk', field: 'left_front_fender' },
    { label: 'Sol Ön Kapı', field: 'left_front_door' },
    { label: 'Sol Arka Kapı', field: 'left_rear_door' },
    { label: 'Sol Arka Çamurluk', field: 'left_rear_fender' },
    { label: 'Tavan', field: 'roof' },
    { label: 'Bagaj', field: 'trunk' },
    { label: 'Sağ Arka Çamurluk', field: 'right_rear_fender' },
    { label: 'Sağ Arka Kapı', field: 'right_rear_door' },
    { label: 'Sağ Ön Kapı', field: 'right_front_door' },
    { label: 'Sağ Ön Çamurluk', field: 'right_front_fender' },
    { label: 'Ön Tampon', field: 'front_bumper' },
    { label: 'Arka Tampon', field: 'rear_bumper' },
    { label: 'Sol Marşpiyel', field: 'left_rocker_board' },
    { label: 'Sağ Marşpiyel', field: 'right_rocker_board' },
  ];

  const accessoryData = [
    {
      category: "İç Donanım",
      items: [
        { label: "Deri Döşeme", field: "leather_upholstery" },
        { label: "Koltuk Soğutma", field: "ventilated_seats" },
        { label: "Koltuk Isıtma", field: "heated_seats" },
        { label: "Vakumlu Kapı", field: "vacuum_doors" },
        { label: "Geri Görüş Kamerası", field: "parking_camera" },
        { label: "360 Kamera", field: "camera_360" },
        { label: "Ön Kamera", field: "front_camera" },
        { label: "Adaptif Cruise Control", field: "adaptive_cruise_control" },
        { label: "Elektrikli Sürücü Koltuğu", field: "electric_driver_seat" },
        { label: "Hafızalı Sürücü Koltuğu", field: "memory_driver_seat" },
        { label: "Elektrikli Yolcu Koltuğu", field: "electric_passenger_seat" },
        { label: "Hafızalı Yolcu Koltuğu", field: "memory_passenger_seat" }
      ]
    },
    {
      category: "Dış Donanım",
      items: [
        { label: "Sunroof", field: "sunroof" },
        { label: "Panoramik Cam Tavan", field: "panorama_roof" },
        { label: "Cam Tavan", field: "black_roof" },
        { label: "Ön Park Sensörü", field: "front_park_sensor" },
        { label: "Arka Park Sensörü", field: "rear_park_sensor" },
        { label: "Zenon Farlar", field: "xenon_lights" },
        { label: "Led Far", field: "led_lights" },
        { label: "Çift Renk", field: "two_color" },
        { label: "Elektrikli Katlanır Aynalar", field: "electric_folding_side_mirrors" },
        { label: "Elektrikli Bagaj", field: "electric_trunk" }
      ]
    },
    {
      category: "Güvenlik",
      items: [
        { label: "Çarpışma Önleyici", field: "anti_collision_system" },
        { label: "Güvenlik Paketi", field: "security_packet" },
        { label: "Şerit Asistanı", field: "line_assist" },
        { label: "Kör Nokta Uyarısı", field: "blind_spot_alarm" }
      ]
    },
    {
      category: "Multimedya",
      items: [
        { label: "Ses Sistemi", field: "sound_system" }
      ]
    }
  ];

  const radioStatus = [
    { label: 'Orijinal', value: 'original', color: 'green' },
    { label: 'Yerel Boya', value: 'local_painted', color: 'success' },
    { label: 'Boyalı', value: 'painted', color: 'warning' },
    { label: 'Değişen', value: 'replaced', color: 'error' },
  ]

  return (
    <div style={{ minHeight: "700px" }}>
      <Grid container justifyContent={'center'} p={2}>
        <Typography variant="h4" p={2} textAlign="center" component="div"  >
          {t("step3Title")}
        </Typography>
        <Typography variant="body1" px={3} textAlign="center" lineHeight={1} component="div"  >
          {t("step3SubTitle")}
        </Typography>
      </Grid>
      <Grid item xs={12}><Divider /></Grid>
      <Grid item xs={12}>
        <Accordion sx={{ boxShadow: 'none' }} expanded={openAccordion === 'damage'} onChange={handleMainAccordionChange('damage')}>
          <AccordionSummary sx={styles.summary} expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle1" >Hasar Tespiti</Typography>
          </AccordionSummary>
          <Divider />
          <AccordionDetails sx={{ overflowX: 'auto' }}>
            <Grid container  >
              <Grid item pb={1} xs={12}>
                <Alert sx={styles.alert} severity="info">
                  Aracınızdaki tüm parçalar orijinal olarak işaretlenmiştir. Doğru teklif çalışılabilmesi için orijinal olmayanların durumunu işaretlemeniz gerekmektedir.
                </Alert>
              </Grid>
            </Grid>
            <Grid container mb={1} p={1} bgcolor={"#000"} borderRadius={1}>
              <Grid item xs={3}>
                <Typography align="center" color='#70C66E'>Orijinal</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography align="center" color='#F0DE00'>Yerel Boya</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography align="center" color='#F0A000'>Boyalı</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography align="center" color='#FF0000'>Değişen</Typography>
              </Grid>
            </Grid>
            {damageData.map(({ label, field }) => (
              <React.Fragment key={field}>
                <Card sx={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: 1, backgroundColor: "#F9F9FB" }}>
                  <Grid container spacing={0} p={1}>
                    <Grid item xs={12} >
                      <Typography align="left">{label}</Typography>
                    </Grid>
                    {radioStatus.map((item) => (
                      <Grid item xs={3} key={item.value}>
                        <Radio
                          color={item.color}
                          checked={damageSelection[field] === item.value}
                          onChange={() => handleDamageChange(field, item.value)}
                          value={item.value}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Card>
              </React.Fragment>
            ))}
          </AccordionDetails>
        </Accordion>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Accordion sx={{ boxShadow: 'none' }} expanded={openAccordion === 'accessory'} onChange={handleMainAccordionChange('accessory')}>
          <AccordionSummary sx={styles.summary} expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle1" >Aksesuar</Typography>
          </AccordionSummary>
          <Divider />
          <AccordionDetails sx={{ overflowX: 'auto', paddingY: '0px' }}>
            <Grid container spacing={0}>
              {accessoryData.map(({ category, items }) => (
                <Grid item xs={12} key={category}>
                  <Accordion key={category} sx={{ boxShadow: 'none' }} expanded={expanded === category} onChange={handleInnerAccordionChange(category)}>
                    <AccordionSummary sx={styles.summary} expandIcon={<ExpandMoreIcon />}>
                      <Typography>{category}</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ overflowX: 'auto' }}>
                      <Grid container mb={1} p={1} bgcolor={"#000"}>
                        <Grid item xs={10} align="left">
                          <Typography color='#fff'>Aksesuar Seçimi</Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography color='#fff'>Var</Typography>
                        </Grid>
                      </Grid>
                      {items.map(({ label, field, value }) => (
                        <React.Fragment key={field}>
                          <Card sx={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: 1, backgroundColor: "#F9F9FB" }}>
                            <Grid container spacing={0} p={1}>
                              <Grid item xs={10} alignContent={"center"}>
                                <Typography align="left">{label}</Typography>
                              </Grid>
                              <Grid item xs={2}>
                                <Checkbox
                                  checked={!!accessorySelection[field]}
                                  onChange={() => handleAccessoryChange(field, !accessorySelection[field])}
                                />
                              </Grid>
                            </Grid>
                          </Card>
                        </React.Fragment>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                  {category !== accessoryData[accessoryData.length - 1].category && (
                    <Divider />
                  )}
                </Grid>
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Divider />
      </Grid>

      <Grid item px={3} pb={3} pt={2} xs={12}>
        <Button variant="contained" onClick={handleNextStep} fullWidth>{t("step2ContinueButton")}</Button>
      </Grid>
    </div>
  );
};

export default Step3;