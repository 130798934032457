//disable yellow errors for this page
/* eslint-disable import/no-anonymous-default-export */

export default {

    tr: {
        translation: {
            languageButton: "TR",
            language: "Tr",

            //Step0
            step0Title: "Aracının değerini öğrenmek ister misin?",
            step0SubTitle: "Aracının plakasını ve km’sini bizimle paylaşır mısın?",
            step0Km: "Kilometre",
            step0LicensePlate: "Aracın Plakası",

            step0KmError: "Lütfen kilometre giriniz",
            step0LicensePlateError: "Lütfen plaka giriniz",

            step0StartButton: "Başla",

            //Step0 Dialog
            step0TitleDialog: "Fiyatlamak istediğiniz araç bu mu?",
            step0ButtonDialogYes: "Bu araçla devam etmek istiyorum",
            step0ButtonDialogNo: "Hayır",

            //Step1
            step1Title: "Aracımı Değerle",
            step1SubTitle: "Aracınızın değerini öğrenmek istiyorsanız doğru yerdesiniz. Başlamak için marka seçiniz.",
            step1PopulerBrand: "Popüler Markalar",

            step1Brand: "Marka",
            step1ModelInfo: "Model Bilgisi",
            step1Model: "Model",
            step1Year: "Model yılı",

            step1BrandError: "Lütfen marka seçiniz",
            step1ModelError: "Lütfen model seçiniz",
            step1YearError: "Lütfen model yılı seçiniz",

            step1Button: "Başla",

            //Step2
            step2Title: "Aracınızın özelliklerini seçin",

            step2GearType: "Vites",
            step2FuelType: "Yakıt",
            step2ShapeType: "Kasa",
            step2VersionType: "Versiyon",
            step2Engine: "Motor",
            step2TrimName: "Donanım",
            step2VehicleFaceLift: "Araç Makyajı",

            step2GearTypeError: "Lütfen vites seçiniz",
            step2FuelTypeError: "Lütfen yakıt seçiniz",
            step2ShapeTypeError: "Lütfen kasa seçiniz",
            step2VersionTypeError: "Lütfen versiyon seçiniz",
            step2EngineError: "Lütfen motor seçiniz",
            step2TrimNameError: "Lütfen donanım seçiniz",
            step2VehicleFaceLiftError: "Lütfen araç makyajı seçiniz",

            step2ContinueButton: "Devam Et",

            //Step3
            step3Name: "Ad",
            step3Surname: "Soyad",
            step3Phone: "Telefon",
            step3Email: "E-Posta",
            step3Service: "Hizmet",
            step3City: "Şehir",
            step3Dealer: "Bayi",
            step3Kvkk: "*İletişim, Kişisel Verilerin saklanması ve işlenmesi onayı",

            step3NameError: "Lütfen adınızı giriniz",
            step3SurnameError: "Lütfen soyadınızı giriniz",
            step3PhoneError: "Lütfen geçerli bir telefon numarası giriniz",
            step3EmailError: "Lütfen geçerli bir e-posta adresi giriniz",
            step3ServiceError: "Lütfen hizmet seçiniz",
            step3CityError: "Lütfen şehir seçiniz",
            step3DealerError: "Lütfen bayi seçiniz",
            step3KvkkError: "Devam etmek için onay verin",
            step3Conditions: "* Mais Motorlu Araçlar İmal ve Satış A.Ş., Yetkili Satıcıları, Yetkili Servisleri, OYAK ve Renault Grup Şirketleri tarafından, Elektronik Ticaretin Düzenlenmesi Hakkındaki Kanun uyarınca, her türlü ticari elektronik ileti gönderilmek suretiyle, tüm iletişim araçları ile tarafınızla iletişime geçilmesine, bu amaçla paylaşmış olduğunuz bilgilerin Renault gizlilik politikası ile kişisel verilerin korunması ve saklanması yasal düzenlemelerine uygun olarak saklanmasına ve işlenmesine muvafakat edersiniz.",

            step3Button: "Aracımı fiyatla",

            step3Sms: "Telefonunuza gelen doğrulama kodunu giriniz.",
            step3Again: "Tekrar gönder",
            step3CheckButton: "Doğrula",

            //Step4
            step4SubTitleValuation1: "Beyanlarınız üzerine, size özel hazırladığımız teklifi aşağıda bulabilirsiniz.",
            step4SubTitleValuation2: "Aracınıza, detaylı ekspertiz sonrası fiyatlandırma yapılacaktır. Sizi yetkili satıcılarımıza bekliyoruz.",
            step4Conditions: `* "renew 150 Nokta Kontrol" öncesi tahmini fiyat bilgisi, tarafınızca girilen araç bilgileri olan markası, modeli, kilometresi ve donanım paketi belirtilen aracın özellikleri dikkate alınarak belirlenmiş olan tahmini ve tamamen tavsiye edilen bir fiyat olup, bağlayıcı değildir . Hesaplamada aracın boya, hasar durumu ve ek donanım özellikleri gözetilmemiştir. <br />* Renew Yetkili Satıcılarının bulundukları lokasyonun pazar şartları ve kendi satış imkânlarına göre farklı bir fiyat belirleme insiyatifleri olduğundan, tavsiye niteliğindeki bu fiyattan farklı bir fiyat tespit edilmesi mümkündür.<br />* Bununla beraber aracınıza "renew 150 Nokta Kontrol" uygulaması tatbik edildikten sonra aracın mekanik ve kaporta aksamındaki durumuna göre de tavsiye edilen fiyattan daha düşük bir fiyatın tespit edilmesi söz konusu olabilecektir. Aracınıza "renew 150 Nokta Kontrol" uygulamasından sonra tespit edilecek fiyatın, tahmini olarak verilen fiyattan düşük olmasından dolayı Renault Mais ve/veya Yetkili Satıcıları'nın herhangi bir sorumluluğu yoktur.<br />* Fiyat teklifi 3 takvim günü için geçerlidir.`

        }
    },
    en: {
        translation: {
            languageButton: "EN",
            language: "En",

            //Step0
            step0Title: "Would you like to know the value of your car?",

        }
    }
}
